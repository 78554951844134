import React from "react";
import classNames from "classnames";

const AlertSuccess = ({ children, fullWidth }) => {
  return (
    <ul
      className={classNames("alertSuccess", {
        "alertSuccess--fullWidth": fullWidth,
      })}
    >
      <li className="alertSuccess__msg">
        <ul>
          <li>{children}</li>
        </ul>
      </li>
    </ul>
  );
};

export default AlertSuccess;
