import { graphql } from "react-apollo";

const EnhanceLogo = ({
  LogoSrcQuery,
}) =>
  graphql(LogoSrcQuery, {
    name: "storeConfiguration",
    props: ({ storeConfiguration }) => ({
      configLoading: !!storeConfiguration?.loading,
      ...(!storeConfiguration?.loading && !storeConfiguration?.error &&
        storeConfiguration?.storeConfiguration
        ),
    }),
  });

export default EnhanceLogo;
