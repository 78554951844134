import React from "react";
import classNames from "classnames";

const AlertError = ({ children, fullWidth }) => {
  return (
    <ul
      className={classNames("alertError", {
        "alertError--fullWidth": fullWidth,
      })}
    >
      <li className="alertError__msg">
        <ul>
          <li>{children}</li>
        </ul>
      </li>
    </ul>
  );
};

export default AlertError;
