import logHandler from "web/core/logs/logHandler";

// For e-commerce events, please read the guidelines at the following url
//  https://segment.com/docs/spec/ecommerce/v2/
//  for GA matching, see https://github.com/segment-integrations/analytics.js-integration-google-analytics/blob/master/test/index.test.js
const trackEvent = (analyticsReady) => (event, properties) => {
  analyticsReady.then((analytics) => {
    if (!analytics) return;

    analytics.ready(() => {
      try {
        analytics.track(event, properties);
      } catch (e) {
        logHandler.getLogger().error(e);
      }
    });
  });
};

// Add userInfos for tracking.
const trackPage = (analyticsReady) => (title, userInfos) => {
  analyticsReady.then((analytics) => {
    if (!analytics) return;

    if (analytics) {
      analytics.ready(() => {
        try {
          // Add userInfos data to page event.
          analytics.page(title, undefined, userInfos);
        } catch (e) {
          logHandler.getLogger().error(e);
        }
      });
    }
  });
};

export default (analyticsReady) => ({
  trackEvent: trackEvent(analyticsReady),
  trackPage: trackPage(analyticsReady),
});
