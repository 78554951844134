import React from "react";
import { compose } from "recompose";
import Loadable from "theme/components/helpers/Loadable";
import Breadcrumb from "theme/components/organisms/Breadcrumb";
import PostPageSeo from "theme/modules/CmsPage/CmsPageSeo";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import WysiwygV2 from "theme/modules/WysiwygV2";
import ButtonLink from "theme/components/atoms/Typography/Link/ButtonLink";
import { FormattedMessage  } from "react-intl";

const PostPage = (props) => {

  const created_at = props.post.created_at;
  const photographer = props.post.photographer;
  const collection = props.post.category_name;

  return (
    <div itemScope itemType="http://schema.org/WebPage">
      <PostPageSeo page={props.post} />
      <div className="page-content">
        <div className="blog-post-container">
          <div className="page-title post-title">
            <h1 itemProp="headline" className="has-border">
              <div className="h1-text">
                <WysiwygV2 content={props.post.titleWysiwyg} />
              </div>
            </h1>
            <div className="post-data__container">
              <span className="post-data__container__label">
                  <FormattedMessage
                    id="pages.PostPage.photographer"
                    defaultMessage="Photographer :"
                  />
                <span>{photographer}</span>
              </span>
              <span className="post-data__container__label">
                <FormattedMessage
                  id="pages.PostPage.collection"
                  defaultMessage="Collection :"
                />
                <span>{collection}</span>
              </span>
              <span className="post-data__container__label">
                <FormattedMessage
                    id="pages.PostPage.date"
                    defaultMessage="Date :"
                />
                <span>{created_at}</span> 
              </span>
            </div>
          </div>
          <article itemProp="mainContentOfPage">
            <WysiwygV2
              containerClass={`kaporalmrs-blog-post-view blog-post-blog-post${props.id}`}
              content={props.post.contentWysiwyg}
            />
          </article>
        </div>
        <ButtonLink appearance="primary" to="/news">
          <FormattedMessage
              id="pages.PostPage.backLink"
              defaultMessage="Back to posts list"
          />
        </ButtonLink>
      </div>
      <Breadcrumb paths={[{ name: props.post.title }]} />
    </div>
  );
};

export default compose(
  Loadable((props) => !props.loading),
  withTrackPage((props) => props.post.title)
)(PostPage);
