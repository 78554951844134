import qs from "web/core/utils/queryString";
import QueryHelper from "./queryHelper";

const makeQueryFromLocation = (location, facetsParams = {}) => {
  const query = new QueryHelper();

  return query.makeNew({
    pageLength: location.state?.pageLength,
    ...qs.parse(location.search),
  }).withFacets(facetsParams);
};

export default makeQueryFromLocation;
