import PropTypes from "prop-types";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import Link from "theme/components/atoms/Typography/Link";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";
import LabelledIcon from "theme/components/molecules/LabelledIcon/LabelledIcon";
import useFullUrl from "web/core/shop/useFullUrl";
import safeHtml from "web/core/utils/safeHtml";

const messages = defineMessages({
  home: {
    id: "components.organisms.Breadcrumb.home",
    defaultMessage: "Home",
  },
});

const Breadcrumb = ({ rootName, paths }) => {
  const intl = useIntl();
  const baseUrl = useFullUrl();

  if (!paths) return null;

  return (
    <>
      <div className="container">
        <ul
          className="breadcrumb"
          itemScope
          itemType="http://schema.org/BreadcrumbList"
        >
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
            className="breadcrumb__item"
          >
            <Link to="/" itemProp="item" appearance="text">
              {rootName ? (
                <LabelledIcon icon="home" appearance="block">
                  <span
                    itemProp="name"
                    dangerouslySetInnerHTML={{ __html: safeHtml(rootName) }}
                  />
                </LabelledIcon>
              ) : (
                <>
                  <meta
                    itemProp="name"
                    content={intl.formatMessage(messages.home)}
                  />
                  <span>{intl.formatMessage(messages.home)}</span>
                </>
              )}
            </Link>
            <meta itemProp="position" content="1" />
            <meta itemProp="@id" content={baseUrl} />
          </li>
          {paths.filter(Boolean)
            .filter(({ display }) => display !== false).map((link, index) => {
            const isPreviousPage = index < paths.length - 1;
            const linkPath = link.path;
            const formattedLink = linkPath?.substring(1) || "";

            const name = (
              <span
                itemProp="name"
                dangerouslySetInnerHTML={{ __html: safeHtml(link.name) }}
              />
            );

            const item =
              isPreviousPage && link.path ? (
                <Link to={link.path} itemProp="item" appearance="text">
                  {name}
                </Link>
              ) : (
                <span>{name}</span>
              );

            return (
              <li
                key={index}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
                className="breadcrumb__item"
              >
                {item}
                <meta itemProp="position" content={index + 2} />
                <meta itemProp="@id" content={baseUrl + formattedLink} />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

Breadcrumb.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        path: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ])
  ),
  rootName: PropTypes.node,
};

export default withHideOnErrorBoundary(Breadcrumb);
