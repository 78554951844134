import React, { useState } from "react";
import Alert from "./Alert";

export const useFormError = () => {
  const [error, setError] = useState(null);
  const [errorQuantity, setErrorQuantity] = useState(null);

  return { error, setError, errorQuantity, setErrorQuantity };
};

export default ({ children }) => (
  <Alert appearance="error-cart" showIcon={false}>
    {children}
  </Alert>
);
