export default [
  "default",
  "default-reverse",
  "primary",
  "primary-reverse",
  "ghost",
  "cookie",
  "link",
  "link-reverse",
  "link-disabled",
  "warning",
  "icon",
  "icon-block",
  "icon-inline",
  "icon-block-primary",
  "rounded",
  "simple-rounded",
  "reverse",
  "simple",
  "black",
  "simple",
  "simple-link",
  "ghost-black",
  "dark-black",
  "obfuscatedLink"
];
