import React, { Component } from "react";
import { Route } from "react-router";
import { trackPage } from "web/core/analytics";
import compose from "recompose/compose";
import { graphql } from "react-apollo";
import UserTrackingQuery from "./userTrackingQuery.gql";
import { sha256 } from "js-sha256";

const withTrackPage = (title, trackPage) => (BaseComponent) => (props) => [
  <TrackPage
    key="track"
    title={typeof title === "function" ? title(props) : title}
    trackPage={trackPage}
  />,
  <BaseComponent key="component" {...props} />,
];

class TrackPageAuxBase extends Component {
  componentDidMount() {
    this.trackPage(null, this.props);
  }

  componentDidUpdate(prevProps) {
    this.trackPage(prevProps, this.props);
  }

  trackPage(prevProps, nextProps) {
    if (
      prevProps &&
      prevProps.location.pathname === nextProps.location.pathname
    ) {
      return;
    }

    const track = this.props.trackPage || trackPage;
    const userData = this.props.userTracking?.data || {
      id: null,
      customer_cylande_id: null,
      email: null,
    };
    const userTracking = {
      loggued: !!userData.id,
      idCustomer: userData.id,
      firstnameCustomer: userData.firstname,
      idCustomerCrm: userData.customer_cylande_id,
      emailCustomer: userData.email && sha256(userData.email)
    };

    // Send infos to tracker from makeTrackers.
    track(nextProps.title, userTracking);
  }

  render() {
    return null;
  }
}

// Add user tracking infos to component.
const TrackPageAux = compose(
  graphql(UserTrackingQuery, {
    props: ({ data }) => {
      return {
        userTracking: {
          data: data.me,
          loading:
            data.loading ||
            (!data.error && typeof data.me === "undefined"),
          error: data.error,
        }
      };
    },
  }),
)(TrackPageAuxBase);

class TrackPage extends Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <TrackPageAux title={this.props.title} location={location} />
        )}
      />
    );
  }
}

export default withTrackPage;
export { TrackPage };
