module.exports = {
  default_image_url: "https://placehold.it/150x220",
  available_page_sizes: [28],
  available_page_orders_sizes: [4, 8, 16],
  mapsKey: "AIzaSyA_l2qafjDH6tthD5MUV3xKu1m5JHc-BOw",
  olapic: {
    checkoutUrl:
      "//photorankstatics-a.akamaihd.net/static/frontend/checkout/olapic.checkout.helper.js",
    apiKey: "80616c6267d01f094e843e0e86931a75f0a9fc899ec9a8046c3348cb758a06ae",
    instance: {
      galery: "dbe6eb9b75d6daf2514be032673e174b",
      home: "7a9e4ee366cf3658bbb0c8941aa9e85a",
      product: "5f5d5417e6b2cf7066eb778f755307f5",
    },
  },
  color_attribute_id: "93",
  website_id: 1,
  tax: 1.2,
  contentSecurityPolicy:
  // /!\ Be aware /!\
  // "*.payline.com" DO NOT COVER url like "https://blabla-bla.cdn.payline.com"
  // You will have to add "*.cdn.payline.com".
    {
      directives: {
        scriptSrc: [
          process.env.FRONT_COMMERCE_CSP_SCRIPTSRC
        ],
        frameSrc: [
          process.env.FRONT_COMMERCE_CSP_FRAMESRC
        ],
        styleSrc: [
          process.env.FRONT_COMMERCE_CSP_STYLESRC
        ],
        mediaSrc: [
          process.env.FRONT_COMMERCE_CSP_MEDIASRC
        ],
        imgSrc: [
          process.env.FRONT_COMMERCE_CSP_IMGSRC
        ],
        connectSrc: [
          process.env.FRONT_COMMERCE_CSP_CONNECTSRC
        ],
        defaultSrc: [
          process.env.FRONT_COMMERCE_CSP_DEFAULTSRC
        ],
        fontSrc: [
          process.env.FRONT_COMMERCE_CSP_FONTSRC
        ],
      },
    },
  homepage_identifier: "home",
  defaultTitle: "Kaporal ® Jeans - Site officiel",
  defaultDescription:
    "Bienvenue sur la boutique en ligne officielle de KAPORAL® pour Homme, Femme &amp; Enfants.\n" +
    "Découvrez les dernières tendances mode: vêtements, jeans, sacs, chaussures et accessoires.",
  themeColor: "#fff",
  search: {
    dynamicFacetSize: 10,
    ignoredAttributeKeys: [
      "url_key",
      "description",
      "name",
      "image",
      "status",
      "tax_class_id",
      "short_description",
    ],
    authorizedCategoriesFacet: [],
  },
  phoneNumber: "05 61 40 54 92",
  email: "contact@front-commerce.com",
  rewrittenToRoot: ["/home"],
  useUserTitles: true,
  cookieMaxAgeInMonths: 12,
};
