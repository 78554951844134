import React from "react";
import PostPage from "theme/pages/Posts/PostPage";

export const dispatchedRoutes = {
  PostPage: (props) => (
    <PostPage
      id={props.matched.entity_id}
      post={props.matched}
      loading={props.loading}
    />
  ),
};
