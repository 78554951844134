import React, { useState, useMemo, useCallback, useEffect } from "react";
import { compose } from "recompose";
import { SvgLoader, SvgProxy } from 'react-svgmt';
import EnhanceLogo from "./EnhanceLogo";
import LogoSrcQuery from "./LogoSrcQuery.gql";

const LOGO_ALTERNATE   = "Kaporal";

/**
 * Logo is now c/c here with fill="currentColor" to enable color change
 * when header change.
 *
 * The svg path element that must change color must have the class "kaporalLogoColor"
 * within.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = ({
  color,
  logoSrc,
  useDefaultLogoBeforeLoading = true,
  configLoading = true,
}) => {
  const [isSvg, setIsSvg] = useState(null);
  const [isSvgLoading, setSvgLoading] = useState(true);

  const logoSrcUrl = useMemo(() => {
    return logoSrc;
  }, [logoSrc]);

  const setSvgLoadingCallback = () => {
    setSvgLoading(false);
  };

  const setIsSvgCallback = useCallback((logoSrc) => {
    const regex = /(\.svg)$/gm;
    if (!logoSrc || logoSrc?.match(regex)?.[0]) {
      setIsSvg(true);
    } else {
      setIsSvg(false);
      setSvgLoading(false);
    }
  }, [setIsSvg, setSvgLoading]);

  // Check if the url contains a svg file.
  useEffect(() => {
    setIsSvgCallback(logoSrcUrl);
  }, [logoSrcUrl, setIsSvgCallback]);

  /**
   * Default logo as inline SVG.
   *
   * @param color
   * @returns {JSX.Element}
   * @constructor
   */
  const DefaultLogo = ({ color }) => {
    return (
      <svg width="97" height="18" viewBox="0 0 97 18" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.92766 17.346V12.2122L5.37121 9.52975L10.6272 17.346H14.085L7.30762 7.40226L13.5087 0.580409H10.0278L2.92766 8.5585V0.580409H0V17.346H2.92766ZM14.5769 17.346L16.5133 13.8541H23.4751L24.0053 17.346H26.9791L24.1898 0.580409H21.1238L11.4187 17.346H14.5769ZM23.1063 11.426H17.8503L21.9998 3.97977L23.1063 11.426ZM30.7854 17.346V11.0097H33.4134C37.3554 11.0097 40.4674 10.0847 40.4674 5.85289C40.4674 1.18166 37.0326 0.580409 33.3673 0.580409H27.8578V17.346H30.7854ZM33.5056 8.60475H30.7854V2.93915H33.3673C33.5314 2.93915 33.6953 2.94048 33.8578 2.94433L33.9795 2.94771C35.8 3.00598 37.4245 3.42015 37.4245 5.87602C37.4245 8.28101 35.7186 8.60475 33.5056 8.60475ZM47.3218 17.6466C53.2693 17.6466 56.7502 12.4897 56.7502 7.07851C56.7502 2.77728 54.03 0.279785 49.9498 0.279785C44.0022 0.279785 40.5213 5.43664 40.5213 10.8479C40.5213 15.1491 43.2415 17.6466 47.3218 17.6466ZM47.6676 15.2416C44.6938 15.2416 43.5642 12.9985 43.5642 10.6166C43.5642 7.24038 45.4315 2.68478 49.604 2.68478C52.5777 2.68478 53.7073 4.92789 53.7073 7.30976C53.7073 10.686 51.84 15.2416 47.6676 15.2416ZM60.6359 17.346V10.2929H63.4713C63.771 10.2929 64.0707 10.2929 64.3473 10.2697L67.8743 17.346H71.1708L67.3441 9.73787C69.4189 9.021 70.4793 7.56413 70.4793 5.41352C70.4793 1.69041 67.9666 0.580409 63.0333 0.580409H57.7082V17.346H60.6359ZM63.5405 7.95726H60.6359V2.93915H63.4483C65.7535 2.93915 67.4825 3.23977 67.4825 5.43664C67.4825 7.44851 66.0532 7.95726 63.5405 7.95726ZM72.3773 17.346L74.3137 13.8541H81.2756L81.8058 17.346H84.7795L81.9902 0.580409H78.9242L69.2192 17.346H72.3773ZM80.9067 11.426H75.6508L79.8002 3.97977L80.9067 11.426ZM97 17.346V14.8716H88.5859V0.580409H85.6582V17.346H97Z"
          fill={
            color || 'currentColor'
          }/>
      </svg>
    );
  }

  // While config info are loading we use (or not) the default svg inline logo.
  if (configLoading) {
    if (useDefaultLogoBeforeLoading) {
      return (
        <div className="logo">
          <DefaultLogo color={color}/>
        </div>
      );
    } else {
      return null;
    }
  }

  // Without URL in BO we display the basic default logo.
  if (!logoSrcUrl) {
    return (
      <div className="logo">
        <DefaultLogo color={color}/>
      </div>
    );
  }

  // If it's not an SVG file, we display it as a basic image file.
  if (isSvg === false && logoSrcUrl) {
    return (
      <div className="logo">
        <img src={logoSrcUrl} alt={LOGO_ALTERNATE} />
      </div>
    );
  }

  // If it's a SVG file we display it as the component, with the default logo while loading.
  return (
    <div className="logo">
      {
        (isSvgLoading && useDefaultLogoBeforeLoading) && <DefaultLogo color={color} />
      }
      {
        logoSrcUrl &&
          <div className={isSvgLoading ? "hidden" : ""}>
            <SvgLoader path={logoSrcUrl} onSVGReady={setSvgLoadingCallback}>
              <SvgProxy selector={".kaporalLogoColor"} fill={color || "currentColor"} />
            </SvgLoader>
          </div>
      }
    </div>
  );
};

export default compose(
  EnhanceLogo({
    LogoSrcQuery
  })
)(Logo);