import React from "react";
import { Helmet } from "react-helmet-async";
import { defineMessages, useIntl } from "react-intl";
import useFullUrl from "web/core/shop/useFullUrl";

const messages = defineMessages({
  defaultDescription: {
    id: "modules.CmsPage.CmsPageSeo.metaDescription",
    defaultMessage: "+ than 1000 Kaporal Jeans Spring / Summer Collection references for Men, Women and Children on the Official Shop",
  },
});


const CmsPageSeo = (props) => {
  const intl = useIntl();
  const fullUrl = useFullUrl(props.page.path);
  const defaultDescription = intl.formatMessage(messages.defaultDescription);
  const metaDescription = props.page?.meta_description || defaultDescription;

  return (
    <Helmet>
      <title>{props.page.meta_title || props.page.title}</title>
      {props.page.meta_keywords && (
        <meta name="keywords" content={props.page.meta_keywords} />
      )}
     {metaDescription &&
        <meta name="description" content={metaDescription} />
      }
      {metaDescription &&
        <meta
          property="og:description"
          content={metaDescription}
        />
      }
      {props.page.path && <link rel="canonical" href={fullUrl} />}
    </Helmet>
  );
};

export default CmsPageSeo;
