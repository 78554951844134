import { Component } from "react";

class ScrollTopOnUpdate extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.location !== nextProps.location;
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.hash) {
      const element = document.querySelector(this.props.location.hash);
      if (element) {
        element.scrollIntoView();
        element.focus();
        return;
      }
    }

    window.scrollTo(0, 0);
  }
  render() {
    return null;
  }
}

export default ScrollTopOnUpdate;
