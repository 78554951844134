import classNames from "classnames";
import invariant from "fbjs/lib/invariant";
import PropTypes from "prop-types";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FiSearch, FiMenu } from "react-icons/fi";
import { HiArrowNarrowLeft } from "react-icons/hi";
import {
  IoIosAdd,
  IoIosAddCircle,
  IoIosArrowDropdown,
  IoIosArrowDropleft,
  IoIosArrowDropright,
  IoIosArrowDropup,
  IoIosArrowRoundDown,
  IoIosBusiness,
  IoIosCalendar,
  IoIosCamera,
  IoIosCard,
  IoIosCash,
  IoIosCheckmark,
  IoIosClose,
  IoIosCloseCircle,
  IoIosCog,
  IoIosCloseCircleOutline,
  IoIosCube,
  IoIosExit,
  IoIosEye,
  IoIosHappy,
  IoIosHome,
  IoIosList,
  IoIosLock,
  IoIosMail,
  IoIosOptions,
  IoIosPhonePortrait,
  IoIosPlay,
  IoIosRedo,
  IoIosRemove,
  IoIosRemoveCircle,
  IoIosShirt,
  IoIosStar,
  IoIosStarOutline,
  IoIosStarHalf,
  IoIosSync,
  IoMdCreate,
  IoIosUndo,
  IoIosDownload,
  IoMdShare,
} from "react-icons/io";
import {
  IoBagOutline,
  IoBag,
  IoHeart,
  IoHeartOutline,
  IoChevronUp,
  IoChevronDown,
  IoChevronForward,
  IoChevronBack
} from "react-icons/io5";
import { ImEye, ImEyeBlocked, ImFacebook, ImTwitter, ImFacebook2 } from "react-icons/im";
import { FaPinterestP } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { GrLocation, GrDocumentPdf, GrClose } from "react-icons/gr";
import { RiMenuFill } from "react-icons/ri";
import { TiMediaPlay, TiMediaPlayReverse, TiSocialPinterest } from "react-icons/ti";
import { VscTrash } from "react-icons/vsc";
import { BsCheckCircle } from "react-icons/bs";
import useFullUrl from "web/core/shop/useFullUrl";

const keyToComponent = {
  "calendar-full": IoIosCalendar,
  "checkmark-circle": BsCheckCircle,
  "chevron-down": IoChevronDown,
  "chevron-down-circle": IoIosArrowDropdown,
  "chevron-left": IoChevronBack,
  "chevron-left-circle": IoIosArrowDropleft,
  "chevron-left-revert": TiMediaPlayReverse,
  "chevron-right": IoChevronForward,
  "chevron-right-circle": IoIosArrowDropright,
  "chevron-right-revert": TiMediaPlay,
  "chevron-up": IoChevronUp,
  "chevron-up-circle": IoIosArrowDropup,
  "circle-minus": IoIosRemoveCircle,
  "credit-card": IoIosCard,
  "cross-circle": IoIosCloseCircle,
  "cross-circle-outline": IoIosCloseCircleOutline,
  "eye-hide": ImEyeBlocked,
  "eye-see": ImEye,
  "header-user": "header-user",
  "left-arrow": "left-arrow",
  "magnifying-glass": "magnifying-glass",
  "phone-handset": IoIosPhonePortrait,
  "plus-circle": IoIosAddCircle,
  "sort-amount-asc": IoIosArrowRoundDown,
  "star-half": IoIosStarHalf,
  "star-outline": IoIosStarOutline,
  'arrow-down': 'arrow-down',
  'bag-filled': IoBag,
  'cart-filled': IoBag,
  avoir: "avoir",
  back: HiArrowNarrowLeft,
  bag: IoBagOutline,
  burger: FiMenu,
  camera: IoIosCamera,
  cart: IoBagOutline,
  cash: IoIosCash,
  checkmark: IoIosCheckmark,
  close: GrClose,
  cog: IoIosCog,
  cross: IoIosClose,
  cube: IoIosCube,
  download: IoIosDownload,
  envelope: IoIosMail,
  exit: IoIosExit,
  eye: IoIosEye,
  filledHeart: IoHeart,
  heart: IoHeartOutline,
  home: IoIosHome,
  list: IoIosList,
  location: GrLocation,
  lock: IoIosLock,
  magnifier: FiSearch,
  menu: RiMenuFill,
  minus: IoIosRemove,
  options: IoIosOptions,
  pdf: GrDocumentPdf,
  pencil: IoMdCreate,
  play: IoIosPlay,
  plus: IoIosAdd,
  redo: IoIosRedo,
  share: IoMdShare,
  shirt: IoIosShirt,
  smile: IoIosHappy,
  star: IoIosStar,
  store: IoIosBusiness,
  sync: IoIosSync,
  trash: VscTrash,
  undo: IoIosUndo,
  user: AiOutlineUser,
  wishHeart: IoHeartOutline,
  imFacebook: ImFacebook,
  ImFacebook2: ImFacebook2,
  imTwitter: ImTwitter,
  tiSocialPinterest: TiSocialPinterest,
  mdEmail: MdEmail,
};

export const whitelist = Object.keys(keyToComponent).sort();

const svgIcons = [
  "credit-card",
  "store",
  "left-arrow",
  "magnifying-glass",
  "close",
  "avoir",
  "arrow-down",
];

const wrapperComponents = {
  default: ({ children }) => {
    return children;
  },
  withAppearance: ({ children, appearance, size }) => {
    const className = classNames("icon-wrapper", {
      [`icon-wrapper--${appearance}`]: appearance,
      [`icon-wrapper--${size}`]: size,
    });
    return <div className={className}>{children}</div>;
  },
};

const Icon = ({ icon, size, title, appearance }) => {
  const baseUrl = useFullUrl();

  invariant(
    whitelist.indexOf(icon) > -1,
    `"${icon}" is not a whitelisted icon`
  );

  if (process.env.NODE_ENV === "development" && title === undefined) {
    console.warn(
      "You must set a title (it can be an empty string if you don't want one) to use <Icon />."
    );
  }

  const className = classNames("icon", {
    [`icon--${size}`]: size,
    [`icon--${appearance}`]: appearance,
    [`icon--${icon}`]: icon,
  });

  const Wrapper = appearance
    ? wrapperComponents.withAppearance
    : wrapperComponents.default;

  if (svgIcons.indexOf(icon) > -1) {
    return (
      <Wrapper appearance={appearance} size={size}>
        <img
          title={title}
          src={`${baseUrl}images/icons/${icon}.svg`}
          alt=""
          className={className}
        />
      </Wrapper>
    );
  } else {
    const IconComponent = keyToComponent[icon];
    return (
      <Wrapper appearance={appearance} size={size}>
        <IconComponent title={title} className={className} />
      </Wrapper>
    );
  }
};

Icon.defaultProps = {
  size: "default",
  appearance: "default",
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf([
    "default",
    "default-block",
    "block",
    "round",
    "round-border",
    "play",
    "plus-circle",
    "plus-circle__clicked",
    "paddles-buttom",
  ]),
  size: PropTypes.oneOf(["default", "small", "mini", "big", "medium", "mid", "large"]),
  title: PropTypes.string.isRequired,
};

export default Icon;
